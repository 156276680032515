<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"
        ><btn-title icon="fas fa-sync-alt">Обновить</btn-title>
      </v-btn>
      <v-btn color="primary" @click="createNew()" title="Создать элемент"><btn-title icon="far fa-plus-square">Создать</btn-title></v-btn></template
    >
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="idEdit"></edit-dialog>
    </portal>
    <a-table-f-api
      ref="table"
      :api="url"
      :model="model"
      :useQuery="true"
      :defaults="defaults"
      @click="onClickRow($event)"
    />
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {
    editDialog: () => import("./dialogs/vendorsDialog"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      title: "",
      model: this.$store.getters["config/get"].models.vendors.list,
      url: "/kazan/vendors",
      defaults: {
        sort: { key: "id", order: "DESC" },
      },
    };
  },
  created() {
    this.$root.title = "Поставщики";
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
